<template>
	<v-flex shrink>
		<w-text-input
			:disabled="disabled"
			inputmode="decimal"
			:label="labelText"
			:readonly="readonly"
			:required="required"
			:value="isFocused ? price : displayedPrice"
			@blur="onBlur($event)"
			@focus="onFocus($event)"
			@keypress="onKeypress($event)"
			@input="price = $event"
		/>
	</v-flex>
</template>

<script>
/**
 * @displayName w-currency-input
 */
export default {
	name: 'WCurrencyInput',
	props: {
		disabled: {
			default: false,
			required: false,
			type: Boolean
		},
		label: {
			default: undefined,
			required: false,
			type: String
		},
		readonly: {
			default: false,
			type: Boolean,
			required: false
		},
		required: {
			default: false,
			required: false,
			type: Boolean
		},
		/**
		 * @model
		 */
		value: {
			default: null,
			type: Number,
			required: false
		}
	},
	data: function () {
		return {
			isFocused: false
		}
	},
	computed: {
		displayedPrice: function () {
			if (!this.value) {
				return ''
			}
			return this.$n(this.value, 'currency')
		},
		labelText: function () {
			if (!this.label) {
				return this.$t('price')
			}
			return this.label
		},
		price: {
			get: function () {
				return this.$n(this.value)
			},
			set: function (val) {
				if (typeof val === 'string' && val.length > 0) {
					val = parseFloat(val.replace(',', '.').replace(/[^0-9. ]+/g, ''))
				} else if (typeof val === 'string' && val.trim().length === 0) {
					val = null
				}
				this.$emit('input', val)
			}
		}
	},
	methods: {
		onBlur: function () {
			this.isFocused = false
		},
		onFocus: function () {
			if (!this.readonly && !this.disabled) {
				this.isFocused = true
			}
		},
		onKeypress: function (event) {
			const key = event.key

			if (key.match(/[^0-9. ]+/g)) {
				event.preventDefault()
			}
		}
	}
}
</script>
